<template>
	<div v-if="loaded">
		<v-row>
			<v-col cols="12" xl="5" lg="5" md="5" sm="12" style="height: calc(100vh - 65px);">
				<div class="h-80" style="overflow-y: auto; overflow-x: hidden;">
					<v-row dense class="m-0 p-0">
						<v-col cols="12" xl="10" class="p-0 text-center">
							<h6 class="font-weight-semibold m-0">{{ order.shipping.contact.name }}</h6>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12" xl="12">
							<v-simple-table>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-right">CANTIDAD</th>
											<th class="text-left">PRODUCTO</th>
											<th class="text-right">PRECIO UNITARIO</th>
											<th class="text-right">TOTAL</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(item, index) in form.items"
											:key="index"
											class="hover"
										>
											<td width="20%" class="text-right">
												{{ item.quantity }}
											</td>
											<td  width="50%">
												{{ item.item.full_name }}
											</td>
											<td width="20%" class="text-right">
												{{ currencyTypeActive.symbol }}
												{{ item.unit_price }}
											</td>
											<td width="10%" class="text-right">
												{{ currencyTypeActive.symbol }} {{ item.total }}
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</div>
				<div class="h-20" style="overflow-y: auto; overflow-x: hidden;">
					<v-divider class="my-4"></v-divider>
					<v-row dense>
						<v-col cols="12" xl="12" lg="12">
							<v-row dense>
								<v-col cols="12" xl="6" lg="6" md="6" sm="12">
									<h5 class="mb-0 font-weight-bold">TOTAL</h5>
								</v-col>
								<v-col cols="12" xl="6" lg="6" md="6" sm="12" class="text-right">
									<h5 class="mb-0 font-weight-bold">
										{{ currencyTypeActive.symbol }} {{ form.total }}
									</h5>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</div>
			</v-col>
			<v-col cols="12" xl="7" lg="7" md="7" sm="12" class="pt-3 hyo secondary">
				<div style="height: calc(84vh - 110px);">
					<v-row dense>
						<v-col cols="12" xl="6" lg="6" md="6" sm="12">
							<v-row dense>
								<v-col cols="12" xl="12" class="text-center">
									<h6>PAGO RÁPIDO EFECTIVO</h6>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12" xl="2" lg="2" md="2" sm="2">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="setAmount(10)"
										>10</v-btn
									>
								</v-col>
								<v-col cols="12" xl="2" lg="2" md="2" sm="2">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="setAmount(20)"
										>20</v-btn
									>
								</v-col>
								<v-col cols="12" xl="2" lg="2" md="2" sm="2">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="setAmount(50)"
										>50</v-btn
									>
								</v-col>
								<v-col cols="12" xl="3" lg="3" md="3" sm="3">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="setAmount(100)"
										>100</v-btn
									>
								</v-col>
								<v-col cols="12" xl="3" lg="3" md="3" sm="3">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="addPaymentExact"
										>{{ form.total }}</v-btn
									>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" xl="6" lg="6" md="6" sm="12">
							<v-row dense>
								<v-col cols="12" xl="12" class="text-center">
									<h6>TARJETAS</h6>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12" xl="6" lg="6" md="6" sm="6">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click.prevent="addPaymentWithCard('01', 4)"
										>VISA</v-btn
									>
								</v-col>
								<v-col cols="12" xl="6" lg="6" md="6" sm="6">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="addPaymentWithCard('02', 5)"
										>MASTERCARD</v-btn
									>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12" xl="12" lg="12">
							<div class="text-center">
								<h6 class="font-weight-medium">TOTAL A PAGAR</h6>
								<h3>{{ currencyTypeActive.symbol }} {{ form.total }}</h3>
							</div>
						</v-col>
						<v-col cols="12" xl="12" lg="12" v-if="form.payments.length == 0">
							<div class="text-center">
								<img alt="discount" src="/static/images/credit-card.png" style="max-height: 96px" />
								<p class="font-weight-medium">Aún no has añadido pagos</p>
							</div>
						</v-col>
						<v-col cols="12" xl="12" lg="12" v-else>
							<v-simple-table>
								<template v-slot:default>
									<!-- <thead>
                                    <tr>
                                    <th class="text-left">Name</th>
                                    <th class="text-left">Calories</th>
                                    </tr>
                                </thead> -->
									<tbody>
										<tr v-for="(row, index) in form.payments" :key="index">
											<td width="30%" class="align-middle">
												<v-icon color="info" left>mdi-credit-card</v-icon>
												<strong>
													{{ row.payment_method.name.toUpperCase() }}
												</strong>
											</td>
											<td width="50%" class="text-right align-middle">
												<strong
													>{{ currencyTypeActive.symbol }}
													{{ row.payment }}</strong
												>
											</td>
											<td width="10%" class="text-right align-middle">
												<v-btn
													icon
													dark
													small
													color="error"
													@click.prevent="clickRemovePayment(index)"
												>
													<v-icon dark>mdi-delete</v-icon>
												</v-btn>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						<v-col cols="12" xl="12" lg="12" md="12" sm="12" class="text-center">
							<v-btn color="accent" @click="addPaymentSelectMethod"
								>AÑADIR PAGO</v-btn
							>
						</v-col>
					</v-row>
					<!-- <v-row dense>
                        <v-col cols="12" xl="6" lg="6">
                            <h5 class="mb-0 font-weight-medium">TOTAL PAGADO</h5>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" class="text-right">
                            <h5 class="mb-0 font-weight-bold">{{currencyTypeActive.symbol}} {{ form.total }}</h5>
                        </v-col>
                    </v-row> -->
				</div>
				<v-row dense>
					<v-col cols="12" xl="6" lg="6" md="6" sm="12">
						<div class="text-center">
							<div :class="{ 'error--text': difference > 0 }">
								<h6
									class="font-weight-bold ma-0"
									v-text="difference > 0 ? 'FALTA' : 'VUELTO'"
								></h6>
								<h5 class="font-weight-bold ma-0">
									{{ currencyTypeActive.symbol }} {{ difference }}
								</h5>
							</div>
						</div>
					</v-col>
					<v-col cols="12" xl="6" lg="6" md="6" sm="12">
						<div class="text-center">
							<h6 class="font-weight-bold ma-0">PAGADO CON</h6>
							<h5 class="font-weight-bold ma-0">
								{{ currencyTypeActive.symbol }} {{ amount }}
							</h5>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="6" lg="6" md="6" sm="12">
						<v-btn
							block
							outlined
							color="error"
							class="white--text"
							:to="{ name: 'SalesReceivables' }"
							>IR A LA LISTA</v-btn
						>
					</v-col>
					<v-col cols="12" xl="6" lg="6" md="6" sm="12">
						<v-btn
							color="accent"
							block
							@click="clickPayment"
							:disabled="button_payment || loadingSubmit"
							:loading="loadingSubmit"
							>FINALIZAR</v-btn
						>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<add-payment
			:showDialogAddPayment.sync="showDialogAddPayment"
			:showPaymentMethods="showPaymentMethods"
			@addPayment="addPayment"
		>
		</add-payment>
	</div>
</template>

<script>
import AddPayment from './Component/AddPayment';
import round from 'lodash/round';
import find from 'lodash/find';
import dayjs from 'dayjs';

export default {
	components: {
		AddPayment,
	},
	// props: ['form', 'customer', 'currencyTypeActive'],
	data() {
		return {
			loaded: false,
			form: {},
			order: {},
			currencyTypeActive: {},
			loadingSubmit: false,
			showDialogAddPayment: false,
			documentNewId: null,
			has_card: false,
			resource: 'orders',
			customers: [],
			amount: 0,
			difference: 0,
			button_payment: false,
			series: [],
			all_series: [],
			cancel: false,
			statusDocument: {},
			payment_method_types: [],
			paymentMethodTypeId: null,
			cardBrandId: null,
			showPaymentMethods: false,
		};
	},
	async created() {
		await this.getRecord();
		await this.getTables();
		await this.initFormPayment();
		await this.inputAmount();
	},
	methods: {
		async getRecord() {
			await this.$http
				.get(`/${this.resource}/record/${this.$route.params.id}`)
				.then((response) => {
					this.order = response.data.data.order;
					
					this.currencyTypeActive = response.data.data.currency_type;
					this.initForm();
				});
		},
		async initForm() {
			this.errors = {};
			this.form = {
				id: this.order.id,
				establishment_id: this.order.establishment_id,
				currency_type_id: this.order.currency_type_id,
				items: this.order.items,
				total: this.order.total,
				payments: [],
			};
		},
		async getTables() {
			await this.$http.get(`/sales-receivables/payment-tables`).then((response) => {
				this.all_series = response.data.series;
				this.payment_method_types = response.data.payment_method_types;

				this.loaded = true;
			});
		},
		async addPaymentExact() {
			this.form.payments = [];
			this.amount = this.form.total;
			let payment_method = find(this.payment_method_types, { id: 1 });
			await this.form.payments.push({
				id: null,
				date_of_payment: dayjs().format('YYYY-MM-DD'),
				payment_method_type_id: 1,
				card_brand_id: null,
				document_id: null,
				order_id: null,
				reference: null,
				payment: this.form.total,
				payment_method: payment_method,
			});
			this.inputAmount();
		},
		async setAmount(amount) {
			if (amount < this.difference) {
				return this.$message.error('Error! El monto es inferior al total a cobrar.');
			}
			if (this.difference > 0) {
				this.amount = parseFloat(this.amount) + parseFloat(amount);
				let exist = find(this.form.payments, { payment_method_type_id: 1 });
				if (exist) {
					exist.payment += round(this.difference, 2);
				} else {
					let payment_method = find(this.payment_method_types, { id: 1 });
					this.form.payments.push({
						id: null,
						date_of_payment: dayjs().format('YYYY-MM-DD'),
						payment_method_type_id: 1,
						card_brand_id: null,
						document_id: null,
						order_id: null,
						reference: null,
						payment: round(this.difference, 2),
						payment_method: payment_method,
					});
				}
				this.inputAmount();
			}
		},
		addPaymentWithCard(card_brand_id, payment_method_type_id) {
			this.cardBrandId = card_brand_id;
			this.paymentMethodTypeId = payment_method_type_id;
			this.showDialogAddPayment = true;
		},
		addPaymentSelectMethod() {
			this.showPaymentMethods = true;
			this.showDialogAddPayment = true;
		},
		addPayment(payment) {
			if (this.difference > 0) {
				this.amount = parseFloat(this.amount) + parseFloat(payment.amount);

				if (this.showPaymentMethods) {
					this.paymentMethodTypeId = payment.payment_method_type_id;
					this.cardBrandId = payment.card_brand_id;
				}

				let exist = find(this.form.payments, {
					payment_method_type_id: this.paymentMethodTypeId,
				});

				if (exist) {
					exist.payment = parseFloat(exist.payment) + parseFloat(payment.amount);
				} else {
					let payment_method = find(this.payment_method_types, {
						id: this.paymentMethodTypeId,
					});
					this.form.payments.push({
						id: null,
						date_of_payment: dayjs().format('YYYY-MM-DD'),
						payment_method_type_id: this.paymentMethodTypeId,
						card_brand_id: this.cardBrandId,
						document_id: null,
						order_id: null,
						has_card: true,
						reference: null,
						payment: Number(payment.amount),
						payment_method: payment_method,
					});
				}
				this.inputAmount();
			}
			this.showPaymentMethods = false;
		},
		async clickRemovePayment(index) {
			this.amount = this.amount - this.form.payments[index].payment;

			this.form.payments.splice(index, 1);

			if (this.form.payments.length == 0) {
				this.button_payment = true;
				this.amount = 0;
			}

			this.inputAmount();
		},
		inputAmount() {
			this.difference = this.form.total - this.amount;

			if (isNaN(this.difference)) {
				this.button_payment = true;
			} else if (this.difference > 0) {
				this.button_payment = true;
			} else {
				this.button_payment = false;
			}
			this.difference = round(this.difference, 2);
		},
		initFormPayment() {
			this.difference = this.form.total;
		},
		async clickCancel() {
			this.$eventHub.$emit('cancelSale');
		},
		async clickBack() {
			this.$eventHub.$emit('backSale');
		},
		sleep(ms) {
			return new Promise((resolve) => setTimeout(resolve, ms));
		},
		async clickPayment() {
			this.loadingSubmit = true;
			
			await this.saveSale();
		},
		async saveSale() {
			let total_payment = 0;
			this.form.payments.forEach((payment) => {
				total_payment += Number(payment.payment);
			});

			if (total_payment > this.form.total) {
				return this.$message.error('El total pagado es superior al monto a cobrar');
			}

			this.loadingSubmit = true;
			await this.$http
				.post(`/${this.resource}/deliver`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$router.push({ name: 'SalesReceivables' });
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loadingSubmit = false;
				});
		},
	},
};
</script>
